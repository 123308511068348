<template>
  <div class="container">
    <!-- <ShortcutButtons /> -->
    <Breadcrumb />
    <div>
      <h3>
        {{ $t("pageTitle.courseIntrosList") }}
        <el-tooltip
          class="item"
          effect="dark"
          content="創建課程"
          placement="top"
          v-show="isActive"
        >
          <router-link to="/courses/create">
            <i class="fas fa-plus" />
          </router-link>
        </el-tooltip>
      </h3>
      <hr />
    </div>

    <el-radio-group v-model="isActive">
      <el-radio-button :label="true">Ongoing</el-radio-button>
      <el-radio-button :label="false">Inactive</el-radio-button>
    </el-radio-group>
    <el-alert
      class="mb-4 mt-4"
      title="拖拽課程進行排序"
      type="info"
      :closable="false"
      show-icon
    >
    </el-alert>
    <div class="subject-title">
      <b class="course-title">課程標題</b>
      <b class="course-fee">表訂費用</b>
      <b class="course-sessions">梯次</b>
      <b class="course-actions">操作</b>
      <b class="course-order"></b>
    </div>
    <draggable :list="courseList" group="materials" @change="resetOrder">
      <div
        class="subject-item"
        v-for="(course, index) in courseList"
        :key="index"
      >
        <div class="course-title">
          <router-link
            :to="{
              name: 'CoursesIntroDetail',
              query: { course_id: course.id }
            }"
          >
            {{ helper.displayI18nText($i18n.locale, course.title) }}
          </router-link>
        </div>
        <div class="course-fee">
          {{ course.displayFee }}
        </div>
        <div class="course-sessions">
          <router-link
            :to="{
              name: 'courseSessionsList',
              query: { course_id: course.id }
            }"
          >
            <el-button size="mini" type="success">
              <i class="el-icon-date"></i>
              <b> {{ course.course_sessions_count }}</b> 個梯次
            </el-button>
          </router-link>
        </div>
        <div class="course-actions">
          <router-link
            :to="{
              name: 'courseIntrosEdit',
              params: { id: course.id }
            }"
          >
            <el-tooltip
              class="item"
              effect="dark"
              content="編輯課程"
              placement="top"
            >
              <el-button type="success" size="mini">
                <i class="fas fa-edit" />
              </el-button>
            </el-tooltip>
          </router-link>
          <el-tooltip
            class="item"
            effect="dark"
            content="暫停課程"
            placement="top"
            v-show="isActive"
          >
            <el-button
              type="success"
              size="mini"
              @click="inactiveCourse(course.id, course)"
              style="margin-left: 10px"
            >
              <i class="far fa-stop-circle" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="繼續課程"
            placement="top"
            v-show="!isActive"
          >
            <el-button
              type="success"
              size="mini"
              @click="activeCourse(course.id, course)"
              style="margin-left: 10px"
            >
              <i class="far fa-play-circle" />
            </el-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="刪除課程"
            placement="top"
          >
            <el-button
              type="danger"
              size="mini"
              @click="handleDelete(course.id)"
              style="margin-left: 10px"
            >
              <i class="far fa-trash-alt" />
            </el-button>
          </el-tooltip>
        </div>
        <div class="course-order">
          <span @click.stop="">
            <el-dropdown trigger="click">
              <span class="more more-icon">
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :disabled="index === 0">
                  <div @click="upData(courseList, index)">
                    {{ $t("newCourses.Move up") }}
                  </div>
                </el-dropdown-item>
                <el-dropdown-item :disabled="index === courseList.length - 1">
                  <div @click="downData(courseList, index)">
                    {{ $t("newCourses.Move down") }}
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </div>
      </div>
    </draggable>
    <br />
  </div>
</template>

<script>
import { helper } from "@ivy-way/material";
import moneyMixin from "@/mixins/money";
import courseApi from "@/apis/course";
import Breadcrumb from "@/components/Breadcrumb";
import draggable from "vuedraggable";

export default {
  metaInfo() {
    return {
      title: `${this.$t("pageTitle.courseIntrosList")} - Ivy-Way Academy`
    };
  },

  components: {
    Breadcrumb,
    draggable
  },

  mixins: [moneyMixin],

  props: [],
  data() {
    return {
      isActive: true,
      pageSize: 10,
      currentPage: 1,
      sessionId: 0,
      courseList: []
    };
  },
  computed: {
    helper() {
      return helper;
    }
  },
  watch: {
    isActive() {
      this.loadCourses();
    }
  },

  async mounted() {
    await this.loadCourses();
  },

  methods: {
    async loadCourses() {
      const priceFormat = course => ({
        ...course,
        displayFee: this.addCurrencySymbol(course.fee, "TWD")
      });
      let coursesRes = await courseApi.fetchCourses({
        is_visible: this.isActive ? 1 : 0
      });
      this.courseList = coursesRes.courses.map(priceFormat);
    },

    handleDelete(id) {
      return async () => {
        await courseApi.deleteCourse(id);
        this.loadCourses();
      };
    },
    async inactiveCourse(id, course) {
      try {
        await courseApi.postCourse(id, { ...course, is_visible: 0 });
        await this.loadCourses();
        this.$message.success("課程已暫停！");
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async activeCourse(id, course) {
      try {
        await courseApi.postCourse(id, { ...course, is_visible: 1 });
        await this.loadCourses();
        this.$message.success("課程已繼續！");
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async resetOrder() {
      let courses = [];
      this.courseList.forEach((course, index) => {
        courses.push({
          id: course.id,
          display_order: index + 1
        });
      });
      await courseApi.updateCoursesDisplayOrders({ courses });
      this.loadCourses();
    },
    swapItems(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    upData(arr, index) {
      let newArr = [];
      if (arr.length > 1 && index !== 0) {
        newArr = this.swapItems(arr, index, index - 1);
      } else {
        newArr = arr;
      }
      arr = newArr;
      this.resetOrder();
    },
    downData(arr, index) {
      let newArr = [];
      if (arr.length > 1 && index !== arr.length - 1) {
        newArr = this.swapItems(arr, index, index + 1);
      } else {
        newArr = arr;
      }
      arr = newArr;
      this.resetOrder();
    }
  }
};
</script>

<style scoped>
.subject-item,
.subject-title {
  border-bottom: 1px solid #ebeef5;
}
.subject-item,
.subject-title {
  font-size: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  line-height: 2rem;
}
.subject-item {
  cursor: pointer;
  height: 60px;
}
.subject-title {
  height: 50px;
}
.subject-item:hover {
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}
.subject-item .more {
  margin-left: 1rem;
  color: white;
}
.subject-item:hover .more {
  color: #606266;
}
.more-icon {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  text-align: center;
  line-height: 2.5rem;
  font-size: 1rem;
}
.more-icon:hover {
  background-color: rgba(32, 33, 36, 0.039);
}

.course-title {
  flex: 1;
}
.course-fee {
  width: 200px;
}
.course-sessions {
  width: 200px;
}
.course-actions {
  width: 160px;
}
.course-order {
  width: 50px;
}
</style>
